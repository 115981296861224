import axios from 'axios';
import { isLocalhost } from '../utils/Utils';
import env from '../env/env';

const makeRequest = async url => {
  const options = {
    headers: {
      'x-api-key': env.API_KEY,
      'Content-Type': 'application/json'
    }
  };

  if (isLocalhost()) {
    options.headers['origin-token'] = env.ORIGIN_TOKEN;
  }

  return axios
    .get(url, options)
    .then(response => response.data)
    .catch(error => error);
};

const wholesaleCustomersNear = (city, brand, limit, offset) => {
  const params = `?near=${city}&brands=${brand}&limit=${limit}&offset=${offset}`;

  const requestURL = `${env.API_URL}/p/customers/${params}`;

  return makeRequest(requestURL);
};

export default wholesaleCustomersNear;
